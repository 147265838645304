import { Box, Button, Center, Flex, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import { convertToCurrency, getValueFromURLParams } from "@utils";
import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
// @ts-ignore
import useSound from "use-sound";
import boopSf from "/assets/sound/hero_decorative-celebration-02.mp3";

export default function SuccessPayment() {
  const price = getValueFromURLParams("amount");
  const message = getValueFromURLParams("message");
  const buttonColorValue = useColorModeValue("#151f21", "gray.900");
  const [play] = useSound(boopSf);
  const navigate = useNavigate();
  const myRef: any = useRef();

  const handleNavigate = () => {
    navigate("/");
  };

  const clickMy = () => {
    myRef?.current?.click();
    const context = new AudioContext();
    context?.resume();
    play();
  };

  useEffect(() => {
    clickMy();
  }, [play, boopSf]);

  return (
    <Center bg={"white"} h={"100vh"} w={"100vw"} pos={"absolute"} top={0} left={0}>
      <Button hidden ref={myRef} onClick={play} />
      <div className="success-animation">
        <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
          <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
          <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
        </svg>
        <Box mt={10}>
          <Stack justify={"center"} align={"center"}>
            {price !== undefined && (
              <Text fontWeight={"semibold"} fontSize={"2xl"}>
                {convertToCurrency(parseInt(price || "0"))}
              </Text>
            )}
            {message !== undefined && (
              <Text fontWeight={"normal"} fontSize={"sm"} maxW={"60vw"} textAlign={"center"}>
                {message}
              </Text>
            )}
          </Stack>
        </Box>
        <Button
          px={8}
          mt={100}
          bg={buttonColorValue}
          color={"white"}
          rounded={"md"}
          _hover={{
            transform: "translateY(-2px)",
            boxShadow: "lg",
          }}
          onClick={handleNavigate}
          w={"full"}
          fontSize={"sm"}
          fontWeight={"semibold"}
        >
          Go Home
        </Button>
      </div>
    </Center>
  );
}
